<template>
  <div>
    <div class="level-item">
      <h1 class="title is-1 page-title">FORGOT PASSWORD</h1>
    </div>

    <!-- Enter email to send to -->
    <div class="box b-shdw-3" v-if="!code">
      <div class="columns">
        <div class="column is-half is-offset-one-quarter" v-if="!emailSent">
          <p class="has-text-centered">
            Enter your email into the box below and we'll send you an email with
            the instructions to reset your password.
          </p>
          <br />

          <input
            type="email"
            class="input"
            placeholder="Enter your email address here"
            v-model="email"
          />
          <button
            class="button is-primary is-fullwidth"
            @click="forgotPassword"
          >
            Reset Password
          </button>
        </div>

        <div class="column is-half is-offset-one-quarter" v-else-if="emailSent">
          <p>
            Instructions have been sent to your email address to reset your
            password.
          </p>
        </div>
      </div>
    </div>

    <!-- Enter new password -->
    <div class="box b-shdw-3" v-if="code">
      <div v-if="!passwordReset">
        <div class="columns">
          <div class="column is-half is-offset-one-quarter">
            <div
              class="notification is-danger is-light"
              v-if="passwordConfirmValid === false"
            >
              <button class="delete"></button>
              Please make sure your password matches.
            </div>
          </div>
        </div>

        <div class="columns">
          <div class="column is-half is-offset-one-quarter">
            Please enter your new password:
            <input
              type="password"
              class="input"
              placeholder="New password"
              v-model="password"
            />
            <br /><br />

            Please enter your new password again:
            <input
              type="password"
              class="input"
              placeholder="Confirm password"
              v-model="passwordConfirm"
            />
            <button
              class="button is-primary is-fullwidth"
              @click="confirmResetPassword"
            >
              Confirm Password
            </button>
          </div>
        </div>
      </div>

      <div v-if="passwordReset === true">
        <div class="columns">
          <div class="column is-half is-offset-one-quarter">
            <div class="notification is-success is-light">
              <button class="delete"></button>
              Password has been reset successfully, you can now login.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerChangePassword',
  data() {
    return {
      email: '',
      password: '',
      passwordConfirm: '',
      emailSent: null,
      passwordReset: null,
      passwordConfirmValid: null,
    };
  },
  props: ['code'],
  methods: {
    forgotPassword() {
      // API create account call
      this.$http
        .post('customer/forgot_password', {
          email: this.email,
        })
        .then((result) => {
          this.emailSent = result.data === 'true';
        })
        .catch((err) => console.log(err));
    },

    confirmResetPassword() {
      this.passwordConfirmValid = this.password === this.passwordConfirm && this.password.length > 5;

      if (this.passwordConfirmValid === true) {
        // API confirm account call
        this.$http
          .post('customer/confirm_reset_password', {
            password: this.password,
            confirmationCode: this.code,
          })
          .then((result) => {
            this.passwordReset = result.data === 'true';
          })
          .catch((err) => console.log(err));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/views/info-page';
button {
  margin-top: 10px;
}
</style>
